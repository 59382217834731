$sm: "screen and (max-width: 414px)";
$lg: "screen and (min-width: 415px)";

@mixin cells() {
    display: flex;
    justify-content: space-between;

    @media #{$sm} {
        flex-direction: column;
        align-items: center;
    }

    &-center {
        justify-content: center;
    }

    &-left {
        justify-content: flex-start;
    }

    &-right {
        justify-content: flex-end;
    }

    &-middle {
        align-items: center;
    }
}

.cells {
    @include cells();
}

@mixin grid() {
    @include cells();
    flex-wrap: wrap;
}

.grid {
    @include grid();
}

.register-box {
    width: 600px;
}

.sign-in_registration {
    .register-logo {
        font-size: 20px;
    }
}
